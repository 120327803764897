import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Chip } from '@material-ui/core'

import { Card, LoadingBox } from 'components'
import { Box, Grid, Typography, Link } from '@material-ui/core'

import { useFetch } from 'hooks'

import * as service from 'service'
import { getBaseURL } from 'service/env'
import formatters from 'helpers/formatters'

const HistoryPayment = ({ paymentOrderId }) => {
  const { response, isLoading } = useFetch(
    service.dponet.preRegistrations.histories,
    {
      paymentOrderId,
    },
  )

  const redirectViewFile = (history) =>
    window.open(`${getBaseURL('dponet') + history?.voucherFile}`, '_blank')

  const histories = response?.data?.paymentConfirmationHistories

  return (
    <Box>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          {isEmpty(histories) ? (
            <Typography variant="body2" color="textPrimary">
              Nenhum histórico encontrado
            </Typography>
          ) : (
            <>
              {histories?.map((history) => (
                <Box key={history?.id}>
                  <Card title={history?.requestAuthor} dropdown paddingCard={1}>
                    <Box p={2}>
                      <Grid spacing={2} container>
                        <Grid item md={6}>
                          <Typography variant="h6" color="textPrimary">
                            Status
                          </Typography>
                          <Typography color="textPrimary" variant="body2">
                            <Chip
                              color="secondary"
                              align="center"
                              size="small"
                              label={history?.status}
                            />
                          </Typography>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <Typography variant="h6" color="textPrimary">
                            Data e hora da requisição
                          </Typography>

                          <Typography color="textPrimary" variant="body2">
                            {formatters.date(history?.createdAt) +
                              ' - ' +
                              formatters.hours(history?.createdAt, false)}
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Typography variant="h6" color="textPrimary">
                            Origem da requisição
                          </Typography>
                          <Typography color="textPrimary" variant="body2">
                            {history?.requestOrigin}
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Typography variant="h6" color="textPrimary">
                            Comprovante de pagamento
                          </Typography>
                          {!isEmpty(history?.voucherFile) && (
                            <Link
                              href="#"
                              onClick={() => redirectViewFile(history)}
                            >
                              Visualizar
                            </Link>
                          )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Typography variant="h6" color="textPrimary">
                            Observação
                          </Typography>

                          <Typography color="textPrimary" variant="body2">
                            {history?.description || 'Sem observação'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Box>
              ))}
            </>
          )}
        </>
      )}
    </Box>
  )
}

HistoryPayment.propTypes = {
  paymentOrderId: PropTypes.number,
}

export default HistoryPayment
