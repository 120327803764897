import constants from 'constants/paymentOrder'

const chipStatusColor = (status, classes) => {
  switch (status) {
    case constants.STATUSES.NOT_PAID_STATUS:
      return classes.chipNotPaid
    case constants.STATUSES.WAITING_PAYMENT_STATUS:
      return classes.chipWaitingPayment
    case constants.STATUSES.WAITING_CONFIRM_STATUS:
      return classes.chipWaitingConfirm
    case constants.STATUSES.PAID_STATUS:
      return classes.chipPaid
    case constants.STATUSES.CANCELED_STATUS:
      return classes.chipCanceled
    case constants.STATUSES.FAILURE_STATUS:
      return classes.chipFailure
    case constants.STATUSES.OVERDUE_STATUS:
      return classes.chipFailure
    case constants.STATUSES.INTEGRATION_STATUS:
      return classes.chipIntegration
    case constants.STATUSES.PRE_CAPTURED_STATUS:
      return classes.chipWaitingConfirm
    case constants.STATUSES.LOSS_STATUS:
      return classes.chipLoss
    case constants.STATUSES.WAITING_NFE_STATUS:
      return classes.chipBlack
    case constants.STATUSES.WAITING_GATEWAY_STATUS:
      return classes.chipBlack
    default:
      return null
  }
}

export default chipStatusColor
