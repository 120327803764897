import constants from 'constants/paymentOrder'

const chipStatusLabel = (status) => {
  switch (status) {
    case constants.STATUSES.NOT_PAID_STATUS:
      return constants.STATUSES_LABELS.NOT_PAID_LABEL
    case constants.STATUSES.WAITING_PAYMENT_STATUS:
      return constants.STATUSES_LABELS.WAITING_PAYMENT_LABEL
    case constants.STATUSES.WAITING_CONFIRM_STATUS:
      return constants.STATUSES_LABELS.WAITING_CONFIRM_LABEL
    case constants.STATUSES.PAID_STATUS:
      return constants.STATUSES_LABELS.PAID_LABEL
    case constants.STATUSES.CANCELED_STATUS:
      return constants.STATUSES_LABELS.CANCELED_LABEL
    case constants.STATUSES.FAILURE_STATUS:
      return constants.STATUSES_LABELS.FAILURE_LABEL
    case constants.STATUSES.OVERDUE_STATUS:
      return constants.STATUSES_LABELS.OVERDUE_LABEL
    case constants.STATUSES.INTEGRATION_STATUS:
      return constants.STATUSES_LABELS.INTEGRATION_LABEL
    case constants.STATUSES.PRE_CAPTURED_STATUS:
      return constants.STATUSES_LABELS.WAITING_CONFIRM_LABEL
    case constants.STATUSES.LOSS_STATUS:
      return constants.STATUSES_LABELS.LOSS_LABEL
    case constants.STATUSES.WAITING_GATEWAY_STATUS:
      return constants.STATUSES_LABELS.WAITING_GATEWAY_LABEL
    case constants.STATUSES.WAITING_NFE_STATUS:
      return constants.STATUSES_LABELS.WAITING_NFE_LABEL
    default:
      return status
  }
}

export default chipStatusLabel
