import api from 'service/api'

const dponetAPI = api.create('dponet')

const changeNfe = async ({ paymentOrderId = '' }) => {
  return await dponetAPI.put(`payment_orders/${paymentOrderId}/change_nfe`)
}

const cancel = async ({ paymentOrderId = '' }) => {
  return await dponetAPI.put(`payment_orders/${paymentOrderId}/cancel`)
}

const resend = async ({ paymentOrderId = '', ...data }) => {
  return await dponetAPI.post(`payment_orders/${paymentOrderId}/resend`, data)
}

const steps = async ({ paymentOrderId = '' }) => {
  return await dponetAPI.get(`payment_orders/${paymentOrderId}/steps`)
}

const lists = async (params) => {
  return await dponetAPI.get('payment_orders', {
    params,
  })
}

const linkedNfe = async ({ paymentOrderId = '', nfe }) => {
  return await dponetAPI.put(`payment_orders/${paymentOrderId}/linked_nfe`, {
    nfe,
  })
}

const recurrencePaymentOrder = async ({
  paymentContractId = '',
  ...params
}) => {
  return await dponetAPI.post('recurrence_payment_order', {
    paymentContractId,
    ...params,
  })
}

const show = async ({ paymentOrderId = '' }) => {
  return await dponetAPI.get(`payment_orders/${paymentOrderId}`)
}

const billet = async ({ paymentOrderId = '' }) => {
  return await dponetAPI.get(`payment_orders/${paymentOrderId}/billet`)
}

const paymentLoss = async ({ paymentOrderId = '' }) => {
  return await dponetAPI.patch(`payment_orders/${paymentOrderId}/payment_loss`)
}

const destroy = async ({ paymentOrderId = '' }) => {
  return await dponetAPI.delete(`payment_orders/${paymentOrderId}`)
}

const paymentOrders = {
  changeNfe,
  cancel,
  resend,
  steps,
  lists,
  linkedNfe,
  recurrencePaymentOrder,
  show,
  billet,
  paymentLoss,
  destroy,
}

export default paymentOrders
